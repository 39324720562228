import {ScreenShotProcessor} from "./ScreenShotService";
import {ServiceContainer} from "../ServiceContainer";
import QueryService from "../QueryService";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";
import {PageNode} from "../../types/PageNode";
import {
    AddTemplateDocument,
    AddTemplateMutation,
    AddTemplateMutationVariables
} from "./NewTemplateScreenShotProcessor.hooks";
import {RequestPageType} from "../../types/RequestPageType";
import {TemplateDimensionType} from "../../types/TemplateDimensionType";
import {UserTemplateListDocument} from "../../components/context-menu/Template/UserTemplate/UserTemplates.hooks";
import {TemplateScreenShotService} from "./TemplateScreenShotService";
import PageService from "../Page/PageService";

export class NewTemplateScreenShotProcessor implements ScreenShotProcessor {
    private apolloClient: ApolloClient<NormalizedCacheObject>;
    private node: PageNode;
    private type: RequestPageType;
    private isAdmin: boolean;

    public constructor(type: RequestPageType, node: PageNode, apolloClient: ApolloClient<NormalizedCacheObject>, isAdmin: boolean) {
        this.apolloClient = apolloClient
        this.type = type
        this.node = node;
        this.isAdmin = isAdmin;
    }

    getNodeId(): string {
        return this.node.id
    }

    onScreenShotAction(): Promise<string> {
        return TemplateScreenShotService.takeTemplateScreenShot(this.node.id)
    }

    onScreenShotReady(result: string): Promise<string> {
        const showLog = ServiceContainer.resolve<QueryService>('QueryService').isDevMode
        let pageId = PageService.getInstance().page.id
        return new Promise((resolve, reject) => {
            this.apolloClient.mutate<AddTemplateMutation, AddTemplateMutationVariables>(
                {
                    mutation: AddTemplateDocument,
                    refetchQueries: [{query: UserTemplateListDocument}],
                    variables: {
                        content: JSON.stringify(this.node),
                        img: result,
                        pageId: pageId,
                        type: this.type === RequestPageType.MobilePage
                            ? TemplateDimensionType.MobileTemplate
                            : TemplateDimensionType.CommonTemplate
                    }
                }
            )
                .then(value => {
                    return resolve(value.data.addTemplate.img)
                })
                .catch(reason => {
                    if (showLog) {
                        console.error("Ошибка отправки скриншота на сервер: ", reason);
                    }
                })

        })
    }

}