/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {ActionCreators} from "redux-undo";
import {Modal} from "bootstrap";
import ExportLoad from "../system/ExportLoad";
import MobileSettingsBar from "./mobileSettingsBar";
import PublishPageState from "../system/PublishPageState";
import {truncateString} from "../../service/imageUploadService";
import {changeActiveModalNode} from "../../reducers/node-modal";
import {ServiceContainer} from "../../service/ServiceContainer";
import QueryService from "../../service/QueryService";
import { changeNode } from '../../reducers/nodes';
import { useClickHandlers } from '../../service/desktopNavigationService';

const MobileNavigationBar = ({isMobileViewDimension, getMetaTag, isMobilePreview, previewState, pageTitle, screenShotService}) => {
    const isExportState = useSelector((state) => state.appStorage.isExportState)
    const isPublishPageState = useSelector((state) => state.appStorage.isPublishPageState)
    const countUndoRedo = useSelector((state) => state.nodes)
    const nodes = useSelector((state) => state.nodes.present.value)
    const activeNode = useSelector((state) => state.nodeModal.value)
    const dispatch = useDispatch();
    const isMeQrMenu = ServiceContainer.resolve < QueryService > ('QueryService').token
    const {handlerClickPublish} = useClickHandlers(dispatch);
    return (<>
            <nav className="navbar first-navbar-menu navbar-expand-lg  fixed-top">
                <div className="container justify-content-between align-items-center">
                    {!isMobilePreview && !isMeQrMenu ?
                        <MobileSettingsBar dispatch={dispatch} getMetaTag={getMetaTag}
                                           activeNode={activeNode}
                                           isMobileViewDimension={isMobileViewDimension}
                                           isMobilePreview={isMobilePreview}
                                           screenShotService={screenShotService}/> :
                        <></>
                    }
                    <div className="text-start">
                        <nav>
                            <ul className="navbar-nav d-block">
                                <li className="nav-item d-inline pe-3">
                                    <a
                                        className={`nav-link d-inline`}
                                        onClick={() => {
                                            Modal.getOrCreateInstance('#global-settings-modal').show()
                                        }}><i className="bi bi-gear"></i>
                                    </a>
                                </li>
                                <li className="nav-item d-inline">
                                    {countUndoRedo.past.length <= 1 ? (
                                        <a
                                            className="nav-link disabled d-inline"
                                            onClick={() => {
                                                dispatch(ActionCreators.undo());
                                                dispatch(changeNode({ editedNode: nodes }));
                                            }}
                                        >
                                            <i className="bi bi-arrow-left-circle"></i>
                                        </a>
                                    ) : (
                                        <a
                                            className="nav-link d-inline"
                                            onClick={() => {
                                                dispatch(ActionCreators.undo());
                                                dispatch(changeNode({ editedNode: nodes }))
                                            }}
                                        >
                                            <i className="bi bi-arrow-left-circle"></i>
                                        </a>
                                    )}
                                </li>
                                <li className="nav-item d-inline ps-3">
                                    {countUndoRedo.future.length === 0 ? (
                                        <a
                                            className="nav-link disabled d-inline"
                                            onClick={() => dispatch(ActionCreators.redo())}
                                        >
                                            <i className="bi bi-arrow-right-circle"></i>
                                        </a>
                                    ) : (
                                        <a
                                            className="nav-link d-inline"
                                            onClick={() => dispatch(ActionCreators.redo())}
                                        >
                                            <i className="bi bi-arrow-right-circle"></i>
                                        </a>
                                    )}
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="text-center me-4">
                        <p className="text-white mb-0 page-title">{pageTitle ? truncateString(pageTitle, 17) : "title"}</p>
                    </div>
                    {/*<div className="text-end">*/}
                    {/*    <span className="text-secondary">100%</span>*/}
                    {/*</div>*/}
                    <div className="text-end">
                            <div className="btn btn-primary rounded-1 px-2 py-1" onClick={() => {
                                // if (activeNode) {
                                dispatch(changeActiveModalNode(nodes[0]));
                                Modal.getOrCreateInstance('#container-to-add-modal').show()
                                // }
                                // else{
                                //
                                // }
                            }}><i className="bi bi-plus-lg mt-1 text-white"></i></div>
                            {window.self === window.top && (
                                <div className="btn btn-primary rounded-1 px-2 py-1 ms-3" onClick={handlerClickPublish}>
                                    <i className="bi bi-box-arrow-up-right mt-1 text-white"></i>
                                </div>
                            )}
                    </div>
                </div>
            </nav>
            {isExportState &&
                <ExportLoad isExportState={isExportState}/>
            }
            {isPublishPageState &&
                <PublishPageState/>
            }
            <div className={'top-fake-block w-100'}></div>
            {activeNode !== null && activeNode !== undefined && !previewState && isMobileViewDimension &&
                <div className={'top-fake-block w-100'}></div>
            }
        </>
    )

}

export default MobileNavigationBar
