import React, { useEffect, useState } from "react";
import "./../App.scss";
import {useDispatch, useSelector} from "react-redux";
import Node from "./node/node";
import DynamicHeadContent from "./context-menu/block/modules/DynamicHeadContent";
import {PageNode} from "../types/PageNode";
import {RootState} from "../store";
import ScreenShotNodeComponent from "./system/ScreenShotNodeComponent";
import { setMovementStatus } from "../reducers/movementSlice";
import { CustomToast } from "./node/toast/CustomToast";

interface StylesProps {
}

const NodeContainer: React.FC<StylesProps> = () => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const links = useSelector((state: RootState) => state.appStorage.links);
    const previewState = useSelector((state:RootState) => state.appStorage.preview)
    const isPublishPageState = useSelector((state: RootState) => state.appStorage.isPublishPageState)
    const [showToast, setShowToast] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if(nodes && nodes[0].customStyleMobile?.display === "grid"){
            dispatch(setMovementStatus({isMobile: true}))
        }else{
            dispatch(setMovementStatus({isMobile: false}))
        }
    }, [nodes])

    useEffect(() => {
        if(isPublishPageState && window.self === window.top){
            setShowToast(true)
        }
    }, [isPublishPageState])

    return (
        <>
            <div id="mainBlock">
                <div>
                    {nodes.map((node: PageNode) => (
                        <Node isPreviewState={previewState} key={node.id} node={node} />
                    ))}
                    {links.map((link: any, index: any) => (
                        <DynamicHeadContent key={index} path={link} />
                    ))}
                </div>
            </div>
            <CustomToast
              isShow={showToast}
              setShow={setShowToast}
              message={'Published!'}
            />
        </>
    );
}

export default NodeContainer;