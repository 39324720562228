import React from 'react'
import {useQuery} from "@apollo/client";
import {COMMON_CATEGORY_TEMPLATE_LIST} from "../../../apollo/Queries";
import CommonTemplate from "./CommonTemplate";
import {TemplateCategory} from "../../../graphql/types";


const CommonCategoryTemplates = () => {

    const {data: commonCategoryTemplatesData} = useQuery(COMMON_CATEGORY_TEMPLATE_LIST);
    const hashId = "#"
    return <>
        {(commonCategoryTemplatesData && commonCategoryTemplatesData.commonTemplateCategoryList) ?
            commonCategoryTemplatesData.commonTemplateCategoryList.map((templateCategory: TemplateCategory) => (
                (templateCategory.templates && templateCategory.templates.length) ?
                    <div key={templateCategory.title} className="accordion-item border-0">
                        <h2 className="accordion-header border-0">
                            <button
                                className="accordion-button-add-modal accordion-button border-0 text-capitalize fw-bold text-dark-gray"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={hashId + templateCategory.title.replace(/[^a-z]/gi, '')}
                                aria-expanded="false" aria-controls={templateCategory.title.replace(/[^a-z]/gi, '')}>
                                {templateCategory.title}
                            </button>
                        </h2>
                        <div id={templateCategory.title.replace(/[^a-z]/gi, '')} className="accordion-collapse collapse"
                             data-bs-parent="#accordionExample">
                            <div className="accordion-body d-flex flex-wrap">
                                {templateCategory.templates.map((template) => {
                                   return template.isCommon ? <CommonTemplate key={template.id} template={template}/> : <></>
                                })}
                            </div>
                        </div>
                    </div>
                    : <></>
            )) : ''
        }
    </>
}


export default CommonCategoryTemplates
