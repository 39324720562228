import React, { ChangeEvent, useState, useEffect } from "react";
import IconItem from "./IconItem";
import { customIcons, iconOptions } from "../../../data/iconOptions";
import { ImageNode } from "../../../types/PageNode";
import {
  ChangeNodeFunction,
} from "../../../service/editModalService";
import AccordionIconButton from "./AccordionIconButton";
interface IIconAccordionOpenCloseProps {
  editableNode: ImageNode;
  changeNodeFunction: ChangeNodeFunction;
}

enum ICON_MODE {
  OpenIcon = "OpenIcon",
  CloseIcon = "CloseIcon",
}

const IconAccordionOpenClose: React.FC<IIconAccordionOpenCloseProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  const [mode, setMode] = useState(ICON_MODE.OpenIcon);
  const [selectedIconOpen, setSelectedIconOpen] = useState("");
  const [selectedIconClose, setSelectedIconClose] = useState("");
  const [standartIcons, setStandartIcons] = useState(iconOptions);
  const [colorfulIcons, setColorfulIcons] = useState(customIcons);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSelectedIconChange = (editedNode: ImageNode, icon: string) => {
    if (mode === ICON_MODE.OpenIcon) {
      setSelectedIconOpen(icon);
      editedNode.accordionIconOpen = icon;
    } else if (mode === ICON_MODE.CloseIcon) {
      setSelectedIconClose(icon);
      editedNode.accordionIconClose = icon;
    }
    changeNodeFunction(editedNode);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const iconWord = event.target.value.toLowerCase();
    setSearchTerm(iconWord);
  };

  const updateIconsState = (icon: string) => {
    const updatedStandartIcons = iconOptions.filter((el) => el !== icon);
    const updatedColorfulIcons = customIcons.filter((el) => el !== icon);

    if (iconOptions.includes(icon)) {
      setStandartIcons([icon, ...updatedStandartIcons]);
    } else if (customIcons.includes(icon)) {
      setColorfulIcons([icon, ...updatedColorfulIcons]);
    }
  };

useEffect(() => {
    if(mode === ICON_MODE.OpenIcon) {
        updateIconsState(selectedIconOpen)
    } else if(mode === ICON_MODE.CloseIcon) {
        updateIconsState(selectedIconClose)
    }
}, [mode, selectedIconOpen, selectedIconClose]);


  const filteredStandartIcons = standartIcons.filter((icon) =>
    icon.toLowerCase().includes(searchTerm)
  );
  const filteredColorfulIcons = colorfulIcons.filter((icon) =>
    icon.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    if (editableNode.accordionIconOpen) {
      setSelectedIconOpen(editableNode.accordionIconOpen);
    }
    if (editableNode.accordionIconClose) {
      setSelectedIconClose(editableNode.accordionIconClose);
    }
  }, [editableNode]);

  return (
    <>
      <p className="fw-bold fs-5">Select Accordion Icon</p>
      <div className="row mb-3 g-3">
        <div className="col-lg-6">
          <AccordionIconButton
            isActive={mode === ICON_MODE.OpenIcon}
            label="Open Accordion Icon"
            iconKey={selectedIconOpen}
            onClick={() => setMode(ICON_MODE.OpenIcon)}
          />
        </div>
        <div className="col-lg-6">
          <AccordionIconButton
            isActive={mode === ICON_MODE.CloseIcon}
            label="Close Accordion Icon"
            iconKey={selectedIconClose}
            onClick={() => setMode(ICON_MODE.CloseIcon)}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className="mb-4">
          <input
            type="text"
            className="form-control py-3"
            placeholder="Search"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <h3 className="fw-700 fs-6 mb-3">Custom Icons</h3>
          <div className="container-scroll">
            <div className="row g-3">
              {filteredColorfulIcons.map((icon: string, index: number) => (
                <div className="col-lg-1 col-3" key={`${icon}-${index}`}>
                  <IconItem
                    icon={icon}
                    selectedIcon={
                      mode === ICON_MODE.OpenIcon
                        ? selectedIconOpen
                        : selectedIconClose
                    }
                    editableNode={editableNode}
                    handleSelectedIconChange={handleSelectedIconChange}
                    isCustomIcon={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <h3 className="fw-700 fs-6 mb-3">Standard Icons</h3>
          <div className="container-scroll">
            <div className="row g-3">
              {filteredStandartIcons.map((icon: string, index: number) => (
                <div className="col-lg-1 col-3" key={`${icon}-${index}`}>
                  <IconItem
                    icon={icon}
                    selectedIcon={
                      mode === ICON_MODE.OpenIcon
                        ? selectedIconOpen
                        : selectedIconClose
                    }
                    editableNode={editableNode}
                    handleSelectedIconChange={handleSelectedIconChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IconAccordionOpenClose;
