// noinspection GraphQLUnresolvedReference

import {gql} from '@apollo/client';

export const TEMPLATE_LIST = gql`
    {
        templateList {
            id,
            img,
            type,
            content
        }
    }
`;
export const COMMON_TEMPLATE_LIST = gql`
    {
        commonTemplateList {
            id,
            content,
            
            img
        }
    }
`;

export const COMMON_CATEGORY_TEMPLATE_LIST = gql`
    {
      commonTemplateCategoryList {
        id
        title
        templates {
          id
          content
          isCommon
          img
          type
        }
      }
    }

`;

