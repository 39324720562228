import React, { useEffect } from 'react';
import {ButtonNode} from "../../../types/PageNode";
import {CustomStyleField} from "../../../types/CustomStyleField";
import OnClickActionWrapper from "../Wrapper/OnClickActionWrapper";
import { changeIsMobileViewDimension } from '../../../reducers/app';
import { generateMobileStyles, generateStyles } from './TextBlock';

interface ButtonBlockProperty {
    nodeToShow: ButtonNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    handleDoubleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const ButtonBlock: React.FC<ButtonBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick,
                                                           isMobileViewDimension
                                                       }) => {
    const useMobileText = nodeToShow[CustomStyleField.IsMobileTextEnabled] ?? false
    const value = useMobileText && isMobileViewDimension
        ? nodeToShow[CustomStyleField.MobileValue] || nodeToShow[CustomStyleField.Value] || 'Add your custom text'
        : nodeToShow[CustomStyleField.Value] || 'Add your custom text'
    const desktopColorHover = nodeToShow.customStyle?.hoverColor
    const mobileColorHover = nodeToShow.customStyleMobile?.hoverColor
    
    const styles = generateStyles(nodeToShow);
    const mobileStyles = generateMobileStyles(nodeToShow);

    return (<>
        <OnClickActionWrapper nodeToShow={nodeToShow}>
            <style>
            {`
                .hover-color-${nodeToShow.id}{
                  transition: color 0.3s ease;
                &:hover{
                  color: ${!isMobileViewDimension ? (desktopColorHover || "") : (mobileColorHover|| (desktopColorHover || ""))} !important;
                }
                &:hover *{
                  color: ${!isMobileViewDimension ? (desktopColorHover || "") : (mobileColorHover|| (desktopColorHover || ""))} !important;
                }
                }
            `}
      </style>
            {(styles || mobileStyles) ? <style>{styles} {mobileStyles}</style> : ''}
            <div
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            id={'blockContent' + nodeToShow.id}
            className={`${className} hover-color-${nodeToShow.id}`}
            style={styleObject}

                // target={(nodeToShow[CustomStyleField.Target] !== undefined) ? nodeToShow[CustomStyleField.Target] : undefined}
            dangerouslySetInnerHTML={{__html: value}}
            ></div>
        </OnClickActionWrapper>
    </>);
};

export default ButtonBlock;
