import React, { FC, useEffect } from "react";

type Props = {
  isShow: boolean;
  setShow: any;
  message: string;
  isSeenClose?: boolean;
  background?: string;
  text?: string;
};
export const CustomToast: FC<Props> = ({
  isShow,
  setShow,
  message,
  isSeenClose = true,
  background = "bg-dark-gray",
  text = "text-white fw-400",
}) => {
  const closeToast = () => {
    if(isShow) setShow(false);
  };

  useEffect(() => {
    if (isShow) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isShow, setShow]);

  return (
      <div
      className={`position-fixed toast align-items-center ${background} border-0 ${isShow ? "show" : ""}`}
      style={{
        width: "fit-content",
        boxShadow: "none",
        bottom: "15px",
        left: "45%",
      }}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="d-flex">
        <div className="toast-body d-flex align-items-center py-3 pe-3 ps-4">
          <p className={`m-0 ${text}`} style={{ fontSize: "18px" }}>
            {message}
          </p>
          {isSeenClose && (
            <button
              type="button"
              className="btn-close btn-close-white ms-3"
              aria-label="Close"
              onClick={closeToast}
              style={{
                width: "0.8rem",
                height: "0.8rem",
                opacity: "1",
              }}
            ></button>
          )}
        </div>
      </div>
    </div>
  );
};
