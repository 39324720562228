import React from "react";
import { useSelector } from "react-redux";
import { PageNode } from "../../../types/PageNode";
import { RootState } from "../../../store";
import { NodeRenderService } from "../../../service/NodeRenderService";
import Node from "../node";
import CustomStyles from "../../../service/CustomsStyles";

interface InputProps {
  node: PageNode;
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDoubleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  styleObject: any;
  getInnerValue(nodeToShow: PageNode): string | React.JSX.Element;
}

export const SliderElement: React.FC<InputProps> = ({
  node,
  handleClick,
  handleDoubleClick,
  styleObject,
  getInnerValue,
}) => {
  let nodeToShow: PageNode = { ...node };
  const isAdvancedMode = useSelector(
    (state: RootState) => state.appStorage.isAdvancedMode
  );
  const isPreviewState = useSelector(
    (state: RootState) => state.appStorage.preview
  );
  const isMobileViewDimension = useSelector(
    (state: RootState) => state.appStorage.isMobileViewDimension
  );
  const activeNode = useSelector((state: RootState) => state.nodeModal.value);
  const activeContainerNode = useSelector(
    (state: RootState) => state.activeContainerNode.activeContainerNode
  );

  let className = NodeRenderService.getClasses(
    nodeToShow,
    activeNode,
    activeContainerNode,
    isPreviewState,
    isAdvancedMode
  ).join(" ");

  const getBorder = () => {
      let borderStyle = '';
      if (!(nodeToShow.isHidden && !isAdvancedMode) && nodeToShow.id !== '1') {
          if (activeNode && nodeToShow.id === activeNode.id) {
              borderStyle = 'solid-blue'
          }
      }
      return borderStyle;
  }

  const sliderElementsBorderStyle = isPreviewState ? "" : ` border-dashed border-1 border-primary rounded ${getBorder()}`;

  if (isPreviewState && !nodeToShow.nodes.length) {
    return null;
  }

  return (
    <>
      <style>
        {`
          @media (max-width: 576px) {
            #blockContent${nodeToShow.id} .carousel-controls {
              opacity: 0;
            }
          }
        `}
      </style>

      <script>
        {`
          document.addEventListener('DOMContentLoaded', function () {
            const carouselElement = document.querySelector('#blockContent${nodeToShow.id}');
            if (carouselElement) {
              new bootstrap.Carousel(carouselElement, {
                interval: false,
                touch: true 
              });
            }
          });
        `}
      </script>

      <div
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        key={nodeToShow.id}
        id={"blockContent" + nodeToShow.id}
        style={styleObject}
        className={`carousel slide ${className}`}
        data-bs-interval="false"
        data-bs-touch="true"
      >
        <div className={`carousel-indicators${sliderElementsBorderStyle}`}>
          {nodeToShow.nodes.map((node: PageNode, index: number) => (
            <button
              key={node.id}
              type="button"
              data-bs-target={`#blockContent${nodeToShow.id}`}
              data-bs-slide-to={`${index}`}
              className={index === 0 ? "active" : undefined}
              aria-current={index === 0 ? "true" : undefined}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {nodeToShow.nodes.map((node: PageNode, index: number) => (
            <div
              className={`carousel-item${index === 0 ? " active" : ""}`}
              key={node.id}
            >
              <Node isPreviewState={isPreviewState} node={node} />
            </div>
          ))}
          {nodeToShow.nodes.length === 0 && !isPreviewState && (
            <div
              className={`carousel-item active`}
              style={{ background: "rgb(230, 230, 230)", height: "200px" }}
            >
              {getInnerValue(nodeToShow)}
            </div>
          )}
        </div>
        <div className="carousel-controls">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={`#blockContent${nodeToShow.id}`}
            data-bs-slide="prev"
          >
            <span
              className={`carousel-control-prev-icon${sliderElementsBorderStyle}`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={`#blockContent${nodeToShow.id}`}
            data-bs-slide="next"
          >
            <span
              className={`carousel-control-next-icon${sliderElementsBorderStyle}`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <CustomStyles
        node={nodeToShow}
        isMobileViewDimension={isMobileViewDimension}
      />
    </>
  );
};
