import React from "react";

interface IncomeProperties {
    filePath: string;
    size: string,
    onClick?: () => void
    onRemoveFile?: () => void
    fileName: string
}

const UploadedFilePreview: React.FC<IncomeProperties> = ({filePath, size, onClick, onRemoveFile, fileName}: IncomeProperties) => {


    const getPreview = (filePath: string) => {
        if (filePath.indexOf('.jpg') || filePath.indexOf('.png')) {
            return filePath;
        } else {
            return '/logo192.png'
        }
    }

    return <>
        <div className='bg-opacity-10 bg-success border border-success p-2 cursor-pointer'
             onClick={onClick && onClick}
             style={{borderRadius: '4px'}}>
            <div className='row'>
                <div className='col-2 d-flex align-items-center justify-content-center'>
                    <img className='img-fluid' src={getPreview(filePath)} alt='' style={{height: ''}}/>
                </div>
                <div className={'col-8 col-lg-9'}>
                    <div>
                        <div className='text-decoration-none text-dark fs-5 text-truncate overflow-hidden'>
                            {fileName}
                        </div>
                        <div className='text-decoration-none text-secondary fs-6'>
                            {size} MB
                        </div>
                    </div>
                </div>
                <div className='col-2 col-lg-1 text-center'>
                    <i className="bi bi-x-circle text-dark fs-4" onClick={(event) => {
                        event.stopPropagation();
                        onRemoveFile && onRemoveFile()
                    }}></i>
                </div>
            </div>
        </div>
    </>
}
export default UploadedFilePreview;