import { NodeType, PageNode } from "../../../types/PageNode";
import { getNewNode, getNodeWithNewIds } from "../../NodeService";
import { CustomStyleField } from "../../../types/CustomStyleField";

export default class DivSliderNodeFactory {
  public static getSliderNode = (parentNode: PageNode) => {
    const newNode: PageNode = getNewNode(NodeType.DivNode, parentNode, {
      tagName: "div",
      className: "slider",
      type: NodeType.SliderNode,
    });

    const container = getNewNode(NodeType.DivNode, newNode, {
      tagName: "div",
      className: "container-fluid",
      backClassesIndexed: {
        "gx-0": "gx-0",
        dFlex: "d-flex",
        horizontalAlignment: "justify-content-center",
        verticalAlignment: "align-items-center",
      },
      breadCrumbTitle: "container-fluid",
      customStyle: {
        [CustomStyleField.Height]: "200px",
        [CustomStyleField.BackgroundColor]: "rgb(220, 220, 220)",
      },
    });

    const text: PageNode = getNewNode(
      NodeType.TextNode,
      container,
      {
        tagName: "text",
        className: "",
        value: '<p style="font-size: 32px; color: rgb(126, 140, 141)">FIRST SLIDE</p>',
        disallowedAddElements: true,
      }
    );

    container.nodes.push(text);
    newNode.nodes.push(container);

    return getNodeWithNewIds(newNode);
  };
}
