const fontObj = [
    {id: 1, font: 'Arial'},
    {id: 29, font: 'Arial Black'},
    {id: 2, font: 'Roboto'},
    {id: 3, font: 'Comic Sans MS'},
    {id: 4, font: 'Courier New'},
    {id: 5, font: 'Franklin Gothic Medium'},
    {id: 6, font: 'Georgia'},
    {id: 7, font: 'Impact'},
    {id: 8, font: 'Lucida Console'},
    {id: 9, font: 'Lucida Sans Unicode'},
    {id: 10, font: 'Microsoft Sans Serif'},
    {id: 11, font: 'Palatino Linotype'},
    {id: 12, font: 'Sylfaen'},
    {id: 13, font: 'Tahoma'},
    {id: 30, font: 'Terminal'},
    {id: 14, font: 'Times New Roman'},
    {id: 15, font: 'Trebuchet MS'},
    {id: 16, font: 'Verdana'},
    {id: 17, font: 'Ebrima'},
    {id: 18, font: 'MS Gothic'},
    {id: 19, font: 'MV Boli'},
    {id: 21, font: 'Segoe Print'},
    {id: 22, font: 'Segoe UI Historic'},
    {id: 23, font: 'Sitka'},
    {id: 27, font: 'Webdings'},
    {id: 28, font: 'Wingdings'},
]

const fontFamilyFormats = [
    "Arial=Arial",
    "Arial Black=Arial Black",
    "Roboto=Roboto",
    "Comic Sans MS=Comic Sans MS",
    "Courier New=Courier New",
    "Franklin Gothic Medium=Franklin Gothic Medium",
    "Georgia=Georgia",
    "Impact=Impact",
    "Lucida Console=Lucida Console",
    "Lucida Sans Unicode=Lucida Sans Unicode",
    "Microsoft Sans Serif=Microsoft Sans Serif",
    "Palatino Linotype=Palatino Linotype",
    "Sylfaen=Sylfaen",
    "Tahoma=Tahoma",
    "Terminal=Terminal",
    "Times New Roman=Times New Roman",
    "Trebuchet MS=Trebuchet MS",
    "Verdana=Verdana",
    "Ebrima=Ebrima",
    "MS Gothic=MS Gothic",
    "MV Boli=MV Boli",
    "Segoe Print=Segoe Print",
    "Segoe UI Historic=Segoe UI Historic",
    "Sitka=Sitka",
    "Webdings=Webdings",
    "Wingdings=Wingdings"
];

export {fontObj, fontFamilyFormats}