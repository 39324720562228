/**
 * Parses an HTML string and returns a Document object.
 * @param html - The HTML string to parse.
 * @returns Parsed Document.
 */
export const parseHTMLString = (html: string): Document => {
    const parser = new DOMParser();
    return parser.parseFromString(html, "text/html");
};

/**
 * Updates inline styles for elements by converting them into class styles.
 * @param doc - The Document object to process.
 * @returns An object containing a style map and the updated HTML as a string.
 */
export const extractInlineStylesToClasses = (
    doc: Document
): { styleMap: Record<string, string>; updatedHTML: string } => {
    const styleMap: Record<string, string> = {};

    // Select all elements with the "style" attribute
    const elements = doc.querySelectorAll("[style]");

    elements.forEach((element, index) => {
        // Check if the element already has a custom class
        const customClass = Array.from(element.classList).find(cls => cls.startsWith("custom-"));

        let uniqueClass = customClass || `custom-${Date.now()}-${index}`;
        
        // Add a unique custom class if not already present
        if (!customClass) {
            element.classList.add(uniqueClass);
        }

        // Update the style map with the element's inline styles
        const inlineStyles = element.getAttribute("style") || "";
        styleMap[uniqueClass] = inlineStyles;

        // Remove the inline style attribute
        element.removeAttribute("style");
    });

    return { styleMap, updatedHTML: doc.body.innerHTML };
};

/**
 * Merges existing styles with inline styles and updates classes accordingly.
 * @param doc - The Document object to process.
 * @param existingStyles - Pre-existing styles to merge with.
 * @returns An object containing an updated style map and HTML.
 */
export const mergeInlineStylesWithClasses = (
    doc: Document,
    existingStyles: Record<string, string>
): { styleMap: Record<string, string>; updatedHTML: string } => {
    const styleMap: Record<string, string> = { ...existingStyles };
    const classUsageCount: Record<string, number> = {};

    const elements = doc.querySelectorAll("[style]");

    elements.forEach((element, index) => {
        let uniqueClass: string;
        const existingClass = Array.from(element.classList).find(cls => cls.startsWith("custom-"));

        if (existingClass) {
            classUsageCount[existingClass] = (classUsageCount[existingClass] || 0) + 1;

            // Create a new unique class if the existing class is reused
            if (classUsageCount[existingClass] > 1) {
                uniqueClass = `custom-${Date.now()}-${index}`;
                element.classList.remove(existingClass);
                element.classList.add(uniqueClass);
            } else {
                uniqueClass = existingClass;
            }
        } else {
            uniqueClass = `custom-${Date.now()}-${index}`;
            element.classList.add(uniqueClass);
        }

        const inlineStyles = element.getAttribute("style") || "";
        const combinedStyles = mergeStyles(styleMap[uniqueClass] || "", inlineStyles);

        styleMap[uniqueClass] = combinedStyles;
        element.removeAttribute("style");
    });

    // Remove unused styles
    Object.entries(styleMap).forEach(([className, value]) => {
        if (!doc.querySelector(`.${className}`)) {
            delete styleMap[className];
        } else {
            // Remove 'white-space: pre;' from styleMap values
            styleMap[className] = value.replace(/\bwhite-space:\s*pre;?/g, "").trim();
        }
    });

    return { styleMap, updatedHTML: doc.body.innerHTML };
};

/**
 * Merges two sets of CSS styles.
 * @param existingStyles - The existing style string.
 * @param newStyles - The new style string.
 * @returns A combined style string.
 */
export const mergeStyles = (existingStyles: string, newStyles: string): string => {
    const parseStyles = (styleString: string): Record<string, string> => {
        const styleObj: Record<string, string> = {};
        styleString.split(";").forEach(rule => {
            const [property, value] = rule.split(":").map(s => s.trim());
            if (property && value) {
                styleObj[property] = value;
            }
        });
        return styleObj;
    };

    const existingStyleObj = parseStyles(existingStyles);
    const newStyleObj = parseStyles(newStyles);

    const combinedStyles = { ...existingStyleObj, ...newStyleObj };

    return Object.entries(combinedStyles)
        .map(([property, value]) => `${property}: ${value}`)
        .join("; ");
};

/**
 * Updates class names for elements based on existing styles.
 * @param htmlString - The HTML string to process.
 * @param styles - Existing styles to map classes.
 * @returns An object containing a style map and the updated HTML.
 */
export const updateElementClasses = (
    htmlString: string,
    styles: Record<string, string>
): { styleMap: Record<string, string>; updatedHTML: string } => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const elements = Array.from(doc.querySelectorAll("[class]"));
    const styleMap: Record<string, string> = {};
    const classRenameMap: Record<string, string> = {};

    elements.forEach(el => {
        el.classList.forEach(cls => {
            if (cls.startsWith("custom")) {
                if (!classRenameMap[cls]) {
                    classRenameMap[cls] = cls;
                } else {
                    const newClass = `custom-${Date.now()}-${Math.random().toString(36).substring(2)}`;
                    styleMap[newClass] = styles[cls] || "";
                    classRenameMap[cls] = newClass;
                }
            }
        });

        el.className = Array.from(el.classList)
            .map(cls => classRenameMap[cls] || cls)
            .join(" ");
    });

    return { updatedHTML: doc.body.innerHTML, styleMap };
};