import {BackClass} from "./BackClass";
import {NodeTemplate} from "./NodeTemplate";
import {OnClickAction} from "./OnClickAction";

export interface TemplateNode {
    template?: NodeTemplate
}

export interface AdminSettings {
}

export interface PageNode extends TemplateNode{
    /**
     * This property for
     * @see src/components/context-menu/block/modules/containerSize.tsx:20
     */
    isMobileStyleContainerSize?: boolean

    /**
     * For color picking at mobile device
     */
    isMobileStyleColor?: boolean,

    /**
     * For url settings at mobile device
     */
    isMobileStyleStates?: boolean,

    /**
     * Show another text for mobile version
     */
    isMobileTextEnabled?:boolean

    /**
     * For background settings of div element
     */
    isMobileStyleBackground?: boolean,

    /**
     * For image elements
     */
    isMobileStyleImage?: boolean,

    /**
     * For icons
     */
    isMobileStyleIcon?: boolean


    customStyle?: CustomStyle|null,
    customStyleMobile?: CustomStyle|null,
    className?: string
    id: string,
    value?: string,
    mobileValue?: string,
    tagName?: string,
    /**
     * id of parent node
     */
    parentId: string,
    /**
     * This property classes which was entered by user
     */
    classes: string[],
    attributes?: { [key: string]: any }|null,

    /**
     * this is property which was entered by user
     */
    style?: object,
    styleString?: string,

    background?: string,

    /**
     * Hidden for desktop or mobile by user
     */
    hidden?: string

    /**
     * Hidden for user by admin
     */
    isHidden?: boolean,
    /**
     * This is classes for pc version
     */
    backClassesIndexed?: BackClass
    /**
     * This is classes for mobile version
     */
    backClassesMobile?: BackClass

    nodes:PageNode[]

    type: string

    /**
     * Item will be disallowed to add
     */
    disallowedAddElements?: boolean
    breadCrumbTitle?: string
    onClickAction?: OnClickAction
    isMixedBorder?: boolean
    isMixedPadding?: boolean
    isMixedMargin?: boolean
    isMixedBorderRadius?: boolean
    isMobileMixedBorderRadius?:boolean
    isMobileMixedBorder?: boolean
    isMobileMixedPadding?: boolean
    isMobileMixedMargin?: boolean

    //TODO: change it to typed Node
    editSettings?:any
    //TODO: Тудаже
    selectedIcon?:any

    adminSettings?: AdminSettings
    vcardFileId?: number
    expanded?: boolean
    accordionIconClose?: string
    accordionIconOpen?: string
    textMobileClasses?: BackClass
    textClasses?: BackClass
}

export interface NodeWithTarget{
    target: string,
}
export interface ButtonNode extends PageNode,NodeWithTarget {
    buttonType?: string|null,
    href?: string|null,
    pageUrl?: string|null,
}

export interface IFrameNode extends PageNode{

}
export interface DivColNode  extends PageNode{
    /**
     * For size of div element
     */
    isMobileStyleContainerColSize?: boolean
    colSize:number
    colSizeMobile:number
}

export interface DivRowNode  extends PageNode{
    /**
     * Count of div col objects inside
     */
    structure:number
}

export interface ImageNode extends PageNode {
    /**
     * This is image settings for pc
     */
    src?: string|null,
    srcName?: string|null,
    srcSize?: number|null,
    loading?: "eager" | "lazy" | undefined,
    alt?: string|null,
    /**
     * This is image settings for mobile
     */
    srcMobile?: string|null,
    srcNameMobile?: string|null,
    srcSizeMobile?: number|null,
    loadingMobile?: "eager" | "lazy" | undefined,
    altMobile?: string|null

}

export enum NodeType {
    PageNode = 'PageNode',
    DivColNode = 'DivColNode',
    DivContainer = 'DivContainer',
    DivNode = 'DivNode',
    DivRowNode = 'DivRowNode',
    IFrameNode = 'IFrameNode',
    ButtonNode = 'ButtonNode',
    IconNode = 'IconNode',
    TextNode = 'TextNode',
    ImageNode = 'ImageNode',
    Accordion = 'Accordion',
    AccordionBodyNode = 'AccordionBodyNode',
    AccordionHeaderNode = 'AccordionHeaderNode',
    SliderNode = 'SliderNode'
}