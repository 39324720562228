import React from "react";
import {PageNode} from "../../../types/PageNode";
import {ServiceContainer} from "../../../service/ServiceContainer";
import {ScreenShotService} from "../../../service/ScreenShot/ScreenShotService";
import {apolloClient} from "../../../index";
import {UpdateTemplateScreenShotProcessor} from "../../../service/ScreenShot/UpdateTemplateScreenShotProcessor";
import { useTemplateShowQuery } from "./UpdateTemplate.hooks";

interface IncomeProps {
    activeNode: PageNode,
}

const UpdateTemplate: React.FC<IncomeProps> = ({activeNode}) => {
    const {data: template} = useTemplateShowQuery({variables: {id: activeNode.template.id}})

    if(!template){
        return <></>
    }
    
    const handlerExportNode = () => {
        const screenShotService = ServiceContainer.resolve<ScreenShotService>('ScreenShotService')
        screenShotService.runScreenShot(new UpdateTemplateScreenShotProcessor(activeNode, apolloClient))
    };

    return (
        <>
            <button type="button" className="text-start btn btn-sm btn-primary w-100"
                    onClick={(e) => {
                        e.preventDefault();
                        handlerExportNode();
                    }}
            >
                <i className="bi bi-file-richtext me-2"></i>Update Template
            </button>
        </>
    );
};
export default UpdateTemplate
